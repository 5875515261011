/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angularecommerce/core/components/carousel-container/carousel-container.component.ngfactory";
import * as i3 from "@angularecommerce/core/components/carousel-container/carousel-container.component";
import * as i4 from "@angularecommerce/core/services/carousel-banners/carousel-banners.service";
import * as i5 from "@angularecommerce/core/components/carousel/carousel-overlay-content.directive";
import * as i6 from "../../shared/components/welcome/welcome.component.ngfactory";
import * as i7 from "../../shared/components/welcome/welcome.component";
import * as i8 from "@angular/flex-layout";
import * as i9 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "mobileSliderProvider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ae2-carousel-container", [["carouselCode", "home"]], null, null, null, i2.View_Ae2CarouselContainerComponent_0, i2.RenderType_Ae2CarouselContainerComponent)), i1.ɵdid(2, 49152, null, 1, i3.Ae2CarouselContainerComponent, [i4.Ae2CarouselBannersService, i1.ChangeDetectorRef], { config: [0, "config"], carouselCode: [1, "carouselCode"] }, null), i1.ɵqud(335544320, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 1, null, View_HomeComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.Ae2CarouselOverlayContentDirective, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-welcome", [], null, null, null, i6.View_WelcomeComponent_0, i6.RenderType_WelcomeComponent)), i1.ɵdid(7, 114688, null, 0, i7.WelcomeComponent, [i8.ObservableMedia], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.carouselConfig; var currVal_1 = "home"; _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 7, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i9.HomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i9.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
