import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material';
import { AddressPipeModule } from '../../pipes/address/address.module';

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    MatButtonModule,
    AddressPipeModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
