import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whatsapp'
})
export class WhatsappPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const re = value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
    return re;
  }

}
