import { NgModule } from '@angular/core';
import { ActivitiesComponent } from './activities.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatCardModule, MatButtonModule } from '@angular/material';
import { Ae2ActivityListContainerModule } from '@angularecommerce/core/components/activity-list-container';
import { Ae2ReplacePipeModule } from '@angularecommerce/core/pipes/replace';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';


@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    RouterModule,
    Ae2ActivityListContainerModule,
    Ae2ReplacePipeModule,
    Ae2ThumborModule
  ],
  declarations: [ActivitiesComponent],
  exports: [ActivitiesComponent]
})
export class ActivitiesModule { }
