<section>
  <div class="footer-top">
    <div class="footer-margin" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">
      <div fxFlex="15%" fxFlex.xs="auto" class="footer-top-logo"></div>
      <div class="footer-box" fxflex="85%" fxFlex.xs="auto" fxLayout fxLayout.xs="column" fxLayoutAlign="start start">
        <div fxFlex="25%" fxFlex.xs="auto">
          <h2 class="footer-title" routerLink="/">Site</h2>
          <div class="footer-link" routerLink="/primeira-aula">Primeira aula</div>
          <div class="footer-link" routerLink="/coaches">Coaches</div>
          <div class="footer-link" routerLink="/treinos">Treinos</div>
          <div class="footer-link" routerLink="/pacotes">Compre</div>
          <div class="footer-link" routerLink="/agenda">Agenda</div>
        </div>
        <div fxFlex="25%" fxFlex.xs="auto">
          <h2 class="footer-title" routerLink="/treino">A Play45</h2>
          <div class="footer-link" routerLink="/treino">Treino</div>
          <div class="footer-link" routerLink="/resultados">Resultados</div>
          <div class="footer-link" routerLink="/coaching">Coaching</div>
          <div class="footer-link" routerLink="/tecnologia">Tecnologia</div>
        </div>
        <div fxFlex="25%" fxFlex.xs="auto">
          <h2 class="footer-title">Contato</h2>
          <div class="footer-link" routerLink="/contato">Fale conosco</div>
          <div class="footer-address">{{ address | addressCreate}}</div>
          <div fxLayout fxLayoutGap="10px">
            <div class="footer-follow_icon" *ngIf="contactEmail" (click)="targetLint('mailto:'+contactEmail)">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
            <div class="footer-follow_icon" *ngIf="socialTwitter" (click)="targetLint(socialTwitter)">
              <i class="fa fa-twitter-square" aria-hidden="true"></i>
            </div>
            <div class="footer-follow_icon" *ngIf="socialFacebook" (click)="targetLint(socialFacebook)">
              <i class="fa fa-facebook-square" aria-hidden="true"></i>
            </div>
            <div class="footer-follow_icon" *ngIf="socialInstagram" (click)="targetLint(socialInstagram)">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </div>
            <div class="footer-follow_icon" *ngIf="socialSpotify" (click)="targetLint(socialSpotify)">
              <i class="fa fa-spotify" aria-hidden="true"></i>
            </div>
            <div class="footer-follow_icon" *ngIf="socialYoutube" (click)="targetLint(socialYoutube)">
              <i class="fa fa-youtube" aria-hidden="true"></i>
            </div>
            <div class="footer-follow_icon" *ngIf="socialLinkedin" (click)="targetLint(socialLinkedin)">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </div>
            <div class="footer-follow_icon" *ngIf="socialVimeo" (click)="targetLint(socialVimeo)">
              <i class="fa fa-vimeo" aria-hidden="true"></i>
            </div>
            <div class="footer-follow_icon" *ngIf="socialFlickr" (click)="targetLint(socialFlickr)">
              <i class="fa fa-flickr" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div fxFlex="25%" fxFlex.xs="auto"></div>
      </div>
    </div>
    <div class="ae2-footer_bottom" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">
      <div>
        Play45 Fitness © 2018. Todos os direitos reservado.
      </div>
      <div>
        <a href="https://angulare.app" target="_blank" class="ae2-footer_bottom_powered">
          Powered by Angular e-Commerce
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="footer-botton">
        <div class="footer-botton-text" routerLink="/primeira-aula">TREINE UMA SEMANA GRÁTIS</div>
    </div> -->
</section>


<!-- <div>
  <button class="button-link" mat-button routerLink="/treino">
      <span>A Play45</span>
  </button>
  <button class="button-link" mat-button routerLink="/primeira-aula">
      <span>Primeira aula</span>
  </button>
  <button class="button-link" mat-button routerLink="/coaches">
      <span>Coaches</span>
  </button>
  <button class="button-link" mat-button routerLink="/contato">
      <span>Fale conosco</span>
  </button>
  <button class="button-link" mat-button routerLink="/pacotes">
      <span>Compre</span>
  </button>
  <button class="button-link" mat-button routerLink="/agenda">
      <span>Agenda</span>
  </button>
</div>
</div>
<div class="footer-margin">

</div> -->
