<div class="mobileSliderProvider">
    <ae2-carousel-container carouselCode="home" [config]="carouselConfig">
        <ng-template ae2CarouselOverlayContent let-title="title" let-description="description" let-ctaLabel="ctaLabel"
            let-ctaUrl="ctaUrl">
        </ng-template>
    </ae2-carousel-container>
</div>
<app-welcome></app-welcome>

<!-- <app-activities></app-activities>

<app-trainers></app-trainers> -->