import { NgModule } from '@angular/core';
import { TrainersComponent } from './trainers.component';
import { MatListModule, MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2InstructorListContainerModule } from '@angularecommerce/core/components/instructor-list-container';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    FlexLayoutModule,
    RouterModule,
    Ae2InstructorListContainerModule,
    Ae2ThumborModule,
    MatButtonModule
  ],
  declarations: [TrainersComponent],
  exports: [TrainersComponent]
})
export class TrainersModule { }
