import { NgModule } from '@angular/core';
import { Ae2HeaderWidgetModule } from '@angularecommerce/core/components/header-widget';
import { HeaderComponent } from './header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { WhatsappPipeModule } from '../../pipes/whatsapp/whatsapp.module';


@NgModule({
  imports: [
    Ae2HeaderWidgetModule,
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    WhatsappPipeModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule { }
