<ae2-header-widget [config]="headerConfig">
  <ng-template #headerLogoTemplate>
    <div class="fullLogo" fxFlex.sx="auto" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="start center">
      <div routerLink="/" class="ae2-header-logo">&nbsp;</div>
      <div fxLayout fxHide.xs="true" fxHide.sm="true" class="nav" fxLayoutGap="15px">
        <div class="isHover" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
          <a class="nav-link" routerLink="/treino">
            <span>A Play45</span>
          </a>
          <ng-container *ngIf="showSubHeader">
            <div class="subHeader animated fadeIn" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
              <div fxHide.xs="true" fxHide.sm="true" class="nav" fxLayoutGap="15px">
                <button mat-button routerLink="/treino">
                  <a class="nav-link">
                    <span class="primaryColor">O Treino</span>
                  </a>
                </button>
                <button mat-button routerLink="/resultados">
                  <a class="nav-link">
                    <span class="primaryColor">Resultados</span>
                  </a>
                </button>
                <button mat-button routerLink="/coaching">
                  <a class="nav-link">
                    <span class="primaryColor">Coaching</span>
                  </a>
                </button>
                <button mat-button routerLink="/tecnologia">
                  <a class="nav-link">
                    <span class="primaryColor">Tecnologia</span>
                  </a>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
        <button mat-button routerLink="/primeira-aula">
          <a class="nav-link">
            <span>Primeira aula</span>
          </a>
        </button>
        <button mat-button routerLink="/coaches">
          <a class="nav-link">
            <span>Coaches</span>
          </a>
        </button>
        <button mat-button routerLink="/treinos">
          <a class="nav-link">
            <span>Treinos</span>
          </a>
        </button>
      </div>
      <div routerLink="/agenda" (click)="closeByHEaderButton()" class="ae2-header-calendarIcon">
        <!-- <mat-icon>calendar_today</mat-icon> -->
        Agende treinos
      </div>
    </div>

  </ng-template>
  <ng-template #headerMenuTemplate let-menu>
    <div fxHide.xs="true" fxHide.sm="true" class="nav" fxLayout>
      <button mat-button routerLink="/pacotes">
        <a class="nav-link">
          <span>Compre</span>
        </a>
      </button>
      <button mat-button routerLink="/agenda">
        <a class="nav-link">
          <span>Agenda</span>
        </a>
      </button>
      <button class="shopping nav-link" mat-button routerLink="/checkout">
        <mat-icon>shopping_cart</mat-icon>
      </button>

      <ng-container *ngIf="isLoggedIn; else notLoggedIn">
        <div fxLayout fxLayoutAlign="center center">
          <ng-container *ngIf="authStore$ | async as authStore">
            <div class="personIcon">
              <mat-icon>person</mat-icon>
            </div>
            <div>
              <div>
                <ng-container *ngIf="authStore.user.isImpersonate; else notImpersonate">
                  <span class="nav-link sign-title">{{ authStore.user.impersonatorName }} ({{
                                        authStore.user.firstName }})</span>
                </ng-container>
                <ng-template #notImpersonate>
                  <span class="nav-link sign-title">
                    Bem-vindo, {{ authStore.user.firstName }}!
                  </span>
                </ng-template>
              </div>

              <div fxLayout>
                <span fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" fxHide.xs fxHide.sm>
                  <a class="sign" href="#" routerLink="/minha-conta/meus-dados">
                    Minha conta
                  </a>
                </span>
                <span class="sign" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" fxHide.xs fxHide.sm>
                  &nbsp;|&nbsp;
                </span>
                <span fxHide.xs fxHide.sm fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                  <ng-container *ngIf="authStore.user.isImpersonate; else exitNoImpersonate">
                    <a class="sign" href="/impersonate/stop/">
                      Sair
                    </a>
                  </ng-container>
                  <ng-template #exitNoImpersonate>
                    <a class="sign" (click)="logOut()">
                      Sair
                    </a>
                  </ng-template>
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #notLoggedIn>
        <div fxLayout fxLayoutAlign="center center">
          <!-- <div class="personIcon">
                        <mat-icon>person</mat-icon>
                    </div> -->
          <div class="nav-link">
            <!-- <div class="sign-title">Bem-vindo</div> -->
            <div class="sign signWhite">
              <a class="sign signWhite" (click)="openLogin()">
                Entrar
              </a>
              ou
              <a class="sign signWhite" fxHide.xs fxHide.sm (click)="openSignup()">
                cadastre-se
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
  <ng-template #headerSidenavTemplate let-sidenav>
    {{createSideNavFunction(sidenav)}}
    <ng-container *ngIf="sidenav.isOpened">
      <div class="sidenav-close" (click)="sidenav.closeSidenav()">&nbsp;
      </div>
      <div class="sidenav-content animated slideInLeft faster">
        <div class="sidenav-content-menu">
          <ng-container *ngIf="!sidenav.isLoggedIn">
            <div class="sidenav-content-button" (click)="sidenav.closeSidenav();sidenav.openLogin()">Entrar
            </div>
            <div class="sidenav-content-button" (click)="sidenav.closeSidenav();sidenav.openSignup()">
              Cadastre-se</div>
          </ng-container>
          <ng-container *ngIf="sidenav.isLoggedIn">
            <div class="sidenav-content-button" routerLink="/minha-conta/meus-dados">Olá,
              {{(sidenav.authStore$ | async)?.user?.firstName || '' }}
              <br>({{(sidenav.authStore$ | async)?.totalCredits || 0}} créditos)
            </div>
            <div class="sidenav-content-button" (click)="sidenav.logOut(); sidenav.closeSidenav()">
              <mat-icon>exit_to_app</mat-icon> sair
            </div>
          </ng-container>
          <div class="sidenav-content-button-divider"></div>
          <div class="sidenav-content-button" (click)="sidenav.closeSidenav()" routerLink="/pacotes">
            <mat-icon class="sidenav-content-buttonIcon">shopping_cart</mat-icon> Compre
          </div>
          <div class="sidenav-content-button-divider"></div>
          <div class="sidenav-content-button" (click)="sidenav.closeSidenav()" routerLink="/treino">A Play45
          </div>
          <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()" routerLink="/treino">Treino
          </div>
          <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()" routerLink="/resultados">
            Resultados</div>
          <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()" routerLink="/coaching">
            Coaching</div>
          <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()" routerLink="/tecnologia">
            Tecnologia</div>
          <div class="sidenav-content-button-divider"></div>
          <div class="sidenav-content-button" (click)="sidenav.closeSidenav()" routerLink="/primeira-aula">
            Primeira aula</div>
          <div class="sidenav-content-button-divider"></div>
          <div class="sidenav-content-button" (click)="sidenav.closeSidenav()" routerLink="/coaches">Coaches
          </div>
          <div class="sidenav-content-button-divider"></div>
          <div class="sidenav-content-button" (click)="sidenav.closeSidenav()" routerLink="/treinos">Treinos
          </div>
          <div class="sidenav-content-button-divider"></div>
          <div class="sidenav-content-button" (click)="sidenav.closeSidenav()" routerLink="/contato">Fale
            conosco</div>
          <ng-container *ngIf="sidenav.isLoggedIn">
            <div class="sidenav-content-button-divider"></div>
            <div class="sidenav-content-button" (click)="sidenav.closeSidenav()">Minha Conta</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/meus-dados">Meus Dados</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/alterar-senha">Alterar minha senha</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/meus-enderecos">Meus endereços</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/metodos-pagamento">Cartões de crédito</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/meus-pedidos">Meus pedidos</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/minhas-assinaturas">Minhas assinaturas</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/meus-eventos">Meus eventos</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/meus-eventos-automaticos">Horário fixo</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/meus-creditos">Meus créditos</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/compartilhar-creditos">Compartilhamento de créditos</div>
            <div class="sidenav-content-buttonSub" (click)="sidenav.closeSidenav()"
              routerLink="/minha-conta/dados-de-performance">Dados de performance</div>
          </ng-container>
        </div>

      </div>
    </ng-container>
  </ng-template>
  <ng-template #headerSocialTemplate let-settings="settings" let-social>
    <div class="phone" fxLayout fxLayoutGap="15px">
      <ng-container *ngIf="settings?.phone">
        <div><i class="fa fa-phone"></i> <a href="tel:{{settings?.phone}}">{{settings?.phone}}</a></div>
      </ng-container>
      <ng-container *ngIf="settings?.mobile">
        <div><i class="fa fa-whatsapp"></i> <a
            href="https://api.whatsapp.com/send?phone=55{{settings?.mobile | whatsapp}}" target="_blank">{{settings?.mobile}}</a></div>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #headerLoginTemplate></ng-template>

</ae2-header-widget>
<ae2-header-layout-placement-widget></ae2-header-layout-placement-widget>
