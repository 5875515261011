import { NgModule } from '@angular/core';
import { WelcomeComponent } from './welcome.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings/site-settings.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    Ae2SiteSettingsModule
  ],
  declarations: [WelcomeComponent],
  exports: [WelcomeComponent]
})
export class WelcomeModule { }
