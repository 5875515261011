import { Component, OnInit } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  isMobile: boolean;

  constructor(private observableMedia: ObservableMedia) { }

  ngOnInit(): void {
    this.observerMQ();
  }

  observerMQ(): void {
    if (this.observableMedia.isActive('xs')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }


}
