import { Routes } from '@angular/router';
import { Ae2AuthRouteGuardService } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { HomeComponent } from './features/home/home.component';
var ɵ0 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ1 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ2 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ3 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ4 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ5 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ6 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ7 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ8 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ9 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ10 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ11 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ12 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ13 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ14 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ15 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ16 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ17 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ18 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ19 = { someProperty: AE2_ROUTER_CLASSES_DATA };
var routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'minha-conta',
        canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/profile/profile.module#ProfileModule',
        data: ɵ0
    },
    {
        path: 'checkout',
        loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
        data: ɵ1
    },
    {
        path: 'agenda',
        loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
        data: ɵ2
    },
    {
        path: 'evento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ3
    },
    {
        path: 'atividades/:slug/:id',
        loadChildren: 'app/features/activity/activity.module#ActivityModule',
        data: ɵ4
    },
    {
        path: 'atividades/:slugatividade/:slugevento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ5
    },
    {
        path: 'instrutor/:slug/:id',
        loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
        data: ɵ6
    },
    {
        path: 'coaches',
        loadChildren: 'app/features/instructors/instructors.module#InstructorsModule',
        data: ɵ7
    },
    {
        path: 'treinos',
        loadChildren: 'app/features/activities/activities.module#ActivitiesModule',
        data: ɵ8
    },
    {
        path: 'clientes/resetar-senha/:uidb/:token',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ9
    },
    {
        path: 'clientes/resetar-senha',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ10
    },
    // {
    //   path: 'perguntas',
    //   loadChildren: 'app/features/faq/faq.module#FaqModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'termos-e-condicoes',
        loadChildren: 'app/features/termos/termos.module#TermosModule',
        data: ɵ11
    },
    {
        path: 'politica-de-privacidade',
        loadChildren: 'app/features/policy/policy.module#PolicyModule',
        data: ɵ12
    },
    {
        path: 'pacotes',
        loadChildren: 'app/features/packages/packages.module#PackagesModule',
        data: ɵ13
    },
    // {
    //   path: 'sobre',
    //   loadChildren: 'app/features/about/about.module#AboutModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'primeira-aula',
        loadChildren: 'app/features/first-class/first-class.module#FirstClassModule',
        data: ɵ14
    },
    {
        path: 'contato',
        loadChildren: 'app/features/contact/contact.module#ContactModule',
        data: ɵ15
    },
    {
        path: 'treino',
        loadChildren: 'app/features/training/training.module#TrainingModule',
        data: ɵ16
    },
    {
        path: 'resultados',
        loadChildren: 'app/features/results/results.module#ResultsModule',
        data: ɵ17
    },
    {
        path: 'coaching',
        loadChildren: 'app/features/coaching/coaching.module#CoachingModule',
        data: ɵ18
    },
    {
        path: 'tecnologia',
        loadChildren: 'app/features/tecnology/tecnology.module#TecnologyModule',
        data: ɵ19
    },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19 };
