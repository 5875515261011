import { Component, OnInit } from '@angular/core';
import { Ae2SettingsService } from '@angularecommerce/core/services/settings/settings.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: {
    '[class.footer]': 'true'
  }
})
export class FooterComponent implements OnInit {

  contactEmail: string;
  socialTwitter: string;
  socialFacebook: string;
  socialInstagram: string;
  socialSpotify: string;
  socialYoutube: string;
  socialLinkedin: string;
  socialVimeo: string;
  socialFlickr: string;
  address: any;

  public currentYear: Number = new Date().getFullYear();

  constructor(public ae2SettingsService: Ae2SettingsService) {

  }

  ngOnInit(): void {
    this.getSetting();
  }

  getSetting(): void {
    this.ae2SettingsService.get().subscribe(settings => {
      this.contactEmail = settings.site.contactEmail;
      this.socialTwitter = settings.site.socialTwitter;
      this.socialFacebook = settings.site.socialFacebook;
      this.socialInstagram = settings.site.socialInstagram;
      this.socialSpotify = settings.site.socialSpotify;
      this.socialYoutube = settings.site.socialYoutube;
      this.socialLinkedin = settings.site.socialLinkedin;
      this.socialVimeo = settings.site.socialVimeo;
      this.socialFlickr = settings.site.socialFlickr;
      this.address = {
        address1: settings.site.address1,
        address2: settings.site.address2,
        address3: settings.site.address3,
        address4: settings.site.address4,
        city: settings.site.city,
        state: settings.site.state,
        zipCode: settings.site.zipCode,
        country: settings.site.country,
      };
    });
  }

  targetLint(link: string): void {
    window.open(link, '_blank');
  }

}
