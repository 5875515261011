import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Ae2HeaderWidgetConfig, Ae2HeaderMenu, Ae2HeaderLinkToPage, Ae2HeaderLinkToSession, Ae2HeaderSubmenu } from '@angularecommerce/core/components/header-widget';
import { Observable } from 'rxjs/internal/Observable';
import { Ae2AuthState, ae2AuthStoreSelector, ae2AuthErrorStoreSelector } from '@angularecommerce/core/stores/auth';
import { Ae2AuthStores } from '@angularecommerce/core/stores/auth/auth-state';
import { Store } from '@ngrx/store';
import { Ae2HeaderOpenSignInAction, Ae2HeaderOpenSignUpAction } from '@angularecommerce/core/stores/header';
import { Ae2AuthLogoutEffectAction } from '@angularecommerce/core/stores/auth/auth-actions';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { Subject } from 'rxjs/internal/Subject';
import { take } from 'rxjs/internal/operators/take';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { skip } from 'rxjs/internal/operators/skip';
import { filter } from 'rxjs/internal/operators/filter';
import { Language, TranslationService } from 'angular-l10n';
import { Ae2ErrorState } from '@angularecommerce/core/stores/error-state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();

  authStore$: Observable<Ae2AuthState>;
  authErrorStore$: Observable<Ae2ErrorState>;

  isLoggedIn: boolean;

  showSubHeader: boolean;

  isSidenav: any;

  constructor(
    private translationService: TranslationService,
    private ae2FeedBackService: Ae2FeedbackService,
    private store: Store<Ae2AuthStores>,
    private changeDetectorRef: ChangeDetectorRef) {
    this.authStore$ = this.store.select(ae2AuthStoreSelector);
    this.authErrorStore$ = this.store.select(ae2AuthErrorStoreSelector);
  }

  ngOnInit(): void {
    this.headerConfig = new Ae2HeaderWidgetConfig();
    this.headerConfig.menuItems = new Ae2HeaderMenu();
    this.headerConfig.menuItems.items = [
      new Ae2HeaderSubmenu('A Play45', [
        new Ae2HeaderLinkToPage('O Treino', '/treino'),
        new Ae2HeaderLinkToPage('Resultados', '/resultados'),
        new Ae2HeaderLinkToPage('Coaching', '/coaching'),
        new Ae2HeaderLinkToPage('Tecnologia', '/tecnologia'),
      ]),
      new Ae2HeaderLinkToPage('Primeira aula', '/primeira-aula'),
      new Ae2HeaderLinkToPage('Coaches', '/coaches'),
      new Ae2HeaderLinkToPage('Treinos', '/treinos'),
      new Ae2HeaderLinkToPage('Compre', '/pacotes'),
      new Ae2HeaderLinkToPage('Agenda', '/agenda'),
      new Ae2HeaderLinkToPage('Fale conosco', '/contato'),
      new Ae2HeaderLinkToPage('Carrinho', '/checkout'),
    ];
    this.headerConfig.backgroundColor = '#363E70';
    this.headerConfig.transparentOnRoutes = [];
    this.authStore$.subscribe(state => {
      this.isLoggedIn = !!state;
      this.changeDetectorRef.markForCheck();
    });
  }

  logOut(): void {
    this.store.dispatch(new Ae2AuthLogoutEffectAction());

        const destroy$ = new Subject();

        // skip é para nao rodar imaditamente
        this.authStore$
            .pipe(
                takeUntil(destroy$),
                skip(1),
                take(1)
            )
            .subscribe(() => {
                this.ae2FeedBackService.success({
                    message: this.translationService.translate('core.global.messages.users.signOutSuccess'),
                    format: Ae2FeedbackFormat.Notification,
                    duration: 2000
                });
                destroy$.next();
            });

        this.authErrorStore$
            .pipe(
                takeUntil(destroy$),
                filter(state => state && state.type instanceof Ae2AuthLogoutEffectAction),
                take(1)
            )
            .subscribe(() => {
                destroy$.next();
            });
  }

  openLogin(): void {
    this.store.dispatch(new Ae2HeaderOpenSignInAction());
  }

  openSignup(): void {
    this.store.dispatch(new Ae2HeaderOpenSignUpAction());
  }

  mouseEnter(): void {
      this.showSubHeader = true;
  }

  mouseLeave(): void {
        this.showSubHeader = false;
  }

  createSideNavFunction(side: any): void {
    if (!this.isSidenav) {
      this.isSidenav = side;
    }
  }

  closeByHEaderButton(): void {
    if (this.isSidenav) {
      this.isSidenav.closeSidenav();
    }
  }

}
