<section class="wel" [class.animated]="isMobile" [class.slideInUp]="isMobile">
  <h2 class="wel-title">BEM-VINDA(O) A PLAY45!</h2>
  <div class="wel-text">
    <p>O impossível foi feito: criar um treinamento em grupo dinâmico, eficaz e com duração de apenas 45 minutos,
      baseado e inspirado na tecnologia. Nos preocupamos para ser o seu melhor e dar mais a você. Mais resultados. Mais
      confiança. Mais vida.</p>
    <p>Aqui você exercita seu corpo e a mente ao mesmo tempo, guiado por coaches capacitados, capazes de liderar e
      motivá-lo a alcançar seu potencial. Faça parte da nossa transformação. Play45 não se descreve, se vive.</p>
  </div>
  <div class="about">
    <div class="about-content" fxLayout fxLayout.xs="column">
      <div class="about-content-item" fxFlex="50%" fxFlex.xs="auto">
        <div class="about-content-item-image">
          <img src="/assets/images/home/Play.png">
        </div>
        <div class="about-content-item-text">
          “Play” é a nossa filosofia, significa: surpreende, motiva e transforma.
        </div>
      </div>
      <div class="about-content-item" fxFlex="50%" fxFlex.xs="auto">
        <div class="about-content-item-image">
          <img src="/assets/images/home/Stopwatch.png">
        </div>
        <div class="about-content-item-text">
          “45” é o tempo total que os nossos treinos possuem.
        </div>
      </div>
    </div>
  </div>
  <div class="wel-images" fxLayout fxLayout.xs="column" fxLayoutGap="20px">
    <div class="wel-images-img">
      <img src="/assets/images/welcome/img-1.jpg">
    </div>
    <div class="wel-images-img">
      <img src="/assets/images/welcome/img-2.jpg">
    </div>
    <div class="wel-images-img">
      <img src="/assets/images/welcome/img-3.jpg">
    </div>
  </div>

</section>